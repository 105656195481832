import service from "@/utils/request";

export function upload(file) {

    return service(
        {
            url: 'upload',
            method: 'post',
            headers:{
                'Content-Type': 'multipart/form-data'
            },
            data:file
        })
}

<template>
  <div class="content_body">
    <el-row class="top" type="flex">
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="dialogVisible=true"> 提交文章</el-button>
      <el-select v-model="choose" placeholder="请选择下拉选择下拉选择" clearable :style="{width: '200px',marginLeft:'20px'}">
        <el-option v-for="(item, index) in field101Options" :key="index" :label="item.label" :value="item.value"
                   :disabled="item.disabled"></el-option>
      </el-select>
    </el-row>
    <v-md-editor
        v-if="choose===1"
        v-model="con1"
        :include-level="[1, 2,3]"
        :disabled-menus="[]"
        @upload-image="handleUploadImage"
        class="editor">
    </v-md-editor>
    <mavon-editor ref=md
                  v-else-if="choose===2"
                  :code-style="code_style"
                  :ishljs="true"
                  @imgAdd="imgAdd"
                  @imgDel="$imgDel"
                  :toolbars="toolbars"
                  class="editor"
                  @change="change"
                  v-model="con2">
    </mavon-editor>

    <quill-editor

        v-else
        ref="myLQuillEditor"
        v-model="con3"
        :options="editorOption"
        class="editor"
        @blur="onEditorBlur"
        @focus="onEditorFocus"
        @change="onEditorChange">
      >
    </quill-editor>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="50%"
    >
      <el-form ref="elForm" :model="article" :rules="rules" size="medium" label-width="100px">
        <el-form-item label="文章标题" prop="articleTitle">
          <el-input v-model="article.articleTitle" placeholder="请输入文章标题" :maxlength="100" show-word-limit
                    clearable prefix-icon='el-icon-receiving' :style="{width: '100%'}"></el-input>
        </el-form-item>

        <el-form-item label="试卷的标签" prop="tags">
          <el-tag
              class="article_tag"
              :key="tag"
              v-for="tag in article.tags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
            {{ tag }}
          </el-tag>
          <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
        </el-form-item>
        <el-form-item label="文章封面" prop="articleImage">
          <el-upload class="uploader"
                     list-type="picture-card"
                     :show-file-list="false"
                     :action="uploadUrl"
                     :before-upload="articleImageBeforeUpload"
                     :on-success="handleAvatarSuccess">
            <img v-if="article.articleImage" :src="article.articleImage" class="avatar" alt="">
            <i v-else class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">提交</el-button>
  </span>
    </el-dialog>

  </div>

</template>

<script>
import {isEmpty} from "@/utils/StringUtils";
import {mavonEditor} from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import {addArticle} from "@/api/article";
import {upload} from "@/api/upload";
import global from "@/config/global";
import 'highlight.js/styles/monokai-sublime.css'

import hljs from "highlight.js";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import 'highlight.js/styles/monokai-sublime.css'


export default {
  name: "my-mavon-editor",
  components: {
    mavonEditor,
    quillEditor
  },
  data() {
    return {
      dynamicTags: [],
      choose: 1,
      con1: "",
      con2: "",
      con3: "",
      field101Options: [{
        "label": "v-md-editor编辑器",
        "value": 1
      }, {
        "label": "mavon-editor编辑器",
        "value": 2
      }, {
        "label": "富文本编辑器",
        "value": 3
      }],
      dialogVisible: false,
      toolbars: global.toolbars,
      // 上传路径
      inputVisible: false,
      inputValue: '',
      // 上传
      uploadUrl: global.uploadUrl,
      article: {
        userId: this.$store.state.user.id,
        articleContext: "",
        articleImage: "",
        articleTitle: "",
        tags: [],
      },
      rules: {
        articleTitle: [{
          required: true,
          message: '请输入文章标题',
          trigger: 'blur'
        }],
        tags: [{
          required: true,
          message: '请添加标签',
          trigger: 'blur'
        }],
        articleImage: [{
          required: false,
          message: '请输入编辑器',
          trigger: 'blur'
        }],
      },
      //工具栏
      subfield: true,
      code_style: "atom-one-dark",
      editorOption: {
        modules: {
          toolbar: global.toolbarOptions,
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          }
        },
        theme: 'snow',
        placeholder: '请输入正文'
      }
    }
  },
  mounted() {
  },
  methods: {
    // 小于4mb
    articleImageBeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 4
      if (!isRightSize) {
        this.$message.error('文件大小超过 4MB')
      }
      return isRightSize
    },
    // 上传封面图片信息
    handleAvatarSuccess(res, file) {
      this.article.articleImage = res.data.url;
    },

    handleClose(tag) {
      this.article.tags.splice(this.article.tags.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      // 放入
      if (inputValue) {
        this.article.tags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    // 提交文章
    submit() {
      if (isEmpty(this.$store.state.user.id)) {
        this.$message("请登录");
        return;
      }
      if (isEmpty(this.article.articleContext)) {
        this.$message("请填写内容");
        return;
      }
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
        let temp = {
          userId: this.$store.state.user.id,
          articleContext: this.article.articleContext,
          articleImage: this.article.articleImage,
          articleTitle: this.article.articleTitle,
          tags: this.article.tags.join(","),
        }
        addArticle(temp).then(result => {
          console.log(result)
          this.$message({
            type: 'success',
            message: result.data.article
          });
          this.$refs.elForm.resetFields();
          // 跳转到首页
          // this.$router.push("/blog")
        }).catch(error => {
          this.$message(
              {
                message: error.message,
                type: 'error',
                duration: 3 * 1000
              }
          )
        })
      })

    },
    // 添加图片信息
    imgAdd(pos, $file) {
      // 第一步.将图片上传到服务器.
      let formData = new FormData();
      formData.append('file', $file);
      let vm = this.$refs.md;
      upload(formData).then(result => {
        console.log(pos, result.data.url)
        vm.$img2Url(pos, result.data.url);
      }).catch(error => {
        this.$message("图片添加失败" + error.message)
      })
    },
    $imgDel(pos, $file) {
      console.log(pos);
      console.log($file)
    },
    // 改变前后，触发的方法
    change(value, render) {

    },

    /**
     * 上传编辑器里面的信息
     * @param event
     * @param insertImage
     * @param files
     */
    handleUploadImage(event, insertImage, files) {
      // 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
      let formData = new FormData();
      formData.append('file', files[0]);
      upload(formData).then(result => {
        console.log(result.data.url)
        insertImage({
          url: result.data.url,
          width: '178px',
          height: '178px',
        });
      }).catch(error => {
        this.$message("图片添加失败" + error.message)
      })
    },

    // vue-quill-editor
    // 失去焦点事件
    onEditorBlur(e) {
      // console.log('onEditorBlur: ', e)
    },
    // 获得焦点事件
    onEditorFocus(e) {
      // console.log('onEditorFocus: ', e)
    },
    // 内容改变事件
    onEditorChange(e) {
      // console.log('onEditorChange: ', e)
    }
  },
  watch: {
    con1() {
      this.article.articleContext = this.con1;
    },
    con2() {
      this.article.articleContext = this.con2;
    },
    con3() {
      this.article.articleContext = this.con3;
    }
  }
}
</script>

<style scoped>

/*.el-upload__tip {*/
/*  line-height: 1.2;*/
/*}*/
.avatar {
  width: 146px;
  height: 146px;
  display: block;
}

.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.uploader .el-upload:hover {
  border-color: #409EFF;
}

.content_body {
  padding: 10px;
  background-color: #fff;
  min-height: calc(100vh - 60px);
}

.top {
  height: 40px;
  margin: 10px auto;
}

.editor {
  text-align: left;
  min-height: calc(100vh - 120px);;
}

.article_tag {
  margin-right: 10px;
}
</style>